import { graphql } from 'react-relay';
import { useLazyLoadQuery } from 'react-relay/hooks';
import {
  usePanelConfigurationQuery,
  usePanelConfigurationQuery$data,
} from './__generated__/usePanelConfigurationQuery.graphql';

const panelConfiguraionQuery = graphql`
  query usePanelConfigurationQuery {
    panelConfiguration {
      registrationOptions {
        phoneNumber
        email
      }
    }
  }
`;

export default function usePanelConfiguration(): usePanelConfigurationQuery$data['panelConfiguration'] {
  const data = useLazyLoadQuery<usePanelConfigurationQuery>(panelConfiguraionQuery, {});
  return data.panelConfiguration;
}
