import { RegistrationData } from './authApiUtils';

export interface AuthenticationTokens {
  accessToken: string;
  refreshToken: string;
  firstPartyCookie?: true;
}

export interface EmbeddedToken {
  accessToken: string;
  refreshToken?: string;
  firstPartyCookie?: true;
}

export type AuthenticationPasswordUpdateResponse = {
  message?: string;
};

export type AuthenticationEmailUpdateResponse = {
  message?: string;
  newEmail: string;
};
export interface UserData {
  guid: string;
  roles: string[];
  country: string;
  currency: string;
}
type LoginResult = UserData | null;

export interface OnePToken {
  jwt: string;
}

export type SignUp = (registrationData: RegistrationData) => Promise<LoginResult>;
export type AuthenticationTokenState = AuthenticationTokens | null;
export type LoginWithPassword = (
  email: string,
  password: string,
  sessionId?: string,
  twoFaCode?: string,
) => Promise<LoginResult>;
export type LoginWithDemoCredentials = (
  panelistId: string,
  country: string,
) => Promise<LoginResult>;
export type LoginWithOtp = (otp: string) => Promise<LoginResult>;
export type UpdateTokens = (updatedTokens: AuthenticationTokenState) => void;

export interface AuthenticationContextValue {
  signUp: SignUp;
  getAccessToken: (forceReload?: string) => Promise<string | null>;
  login: LoginWithPassword;
  loginWithOtp: LoginWithOtp;
  updateTokens: UpdateTokens;
  isLoggedIn: boolean;
  userData: UserData | null;
  refreshToken: string | null;
  accessToken: string | null;
  onePToken: string | null;
  logOut: (trigger: string) => void;
}

/* eslint max-classes-per-file: ["error", 4] */
export class UpdateAccountError extends Error {
  updateError: string;

  status?: number;

  constructor(message: string, status: number | undefined = undefined) {
    super(message);
    this.updateError = message;
    this.name = 'UpdateAccountError';
    this.status = status;
  }
}

export class RequestPasswordResetError extends Error {
  updateError: string;

  status?: number;

  constructor(message: string, status: number | undefined = undefined) {
    super(message);
    this.updateError = message;
    this.name = 'RequestPasswordResetError';
    this.status = status;
  }
}
export class ResetPasswordError extends Error {
  updateError: string;

  status?: number;

  constructor(message: string, status: number | undefined = undefined) {
    super(message);
    this.updateError = message;
    this.name = 'ResetPasswordError';
    this.status = status;
  }
}

export class LoginError extends Error {
  loginError: string;

  status?: number;
  payload?: any;

  constructor(message: string, status: number | undefined = undefined, payload: any = undefined) {
    super(message);
    this.loginError = message;
    this.name = 'LoginError';
    this.status = status;
    this.payload = payload;
  }
}

export const TOKENS_STORAGE_KEY = 'qurated.tokens';
export const ONEPTOKEN_STORAGE_KEY = 'onep.token';
export const SOURCE_STORAGE_KEY = 'user-source-data';

export type RequestContext = {
  locale?: string;
};
