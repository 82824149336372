const { DEFAULT_LOCALE, ACCEPTED_LOCALES } = require('./src/constants/locales');
const path = require('path');

const LocizeBackend = require('i18next-locize-backend/cjs');

const isBrowser = typeof window !== 'undefined';

const useLocize =
  isBrowser &&
  process.env.NODE_ENV !== 'production' &&
  Boolean(process.env.NEXT_PUBLIC_LOCIZE_PROJECT_ID);

const settings = {
  debug: false,
  backend: {
    projectId: process.env.NEXT_PUBLIC_LOCIZE_PROJECT_ID,
    apiKey: process.env.NEXT_PUBLIC_LOCIZE_SECRET,
    backendOptions: [
      {
        expirationTime: 60 * 60 * 1000, // 1 hour
      },
      {
        projectId: process.env.NEXT_PUBLIC_LOCIZE_PROJECT_ID,
        version: 'latest',
        reloadInterval: false,
      },
    ],
    backends: isBrowser ? [LocizeBackend] : [],
  },
  serializeConfig: false,
  use: isBrowser ? [LocizeBackend] : [],
  saveMissing: process.env.NEXT_PUBLIC_SAFE_MISSING_TRANSLATIONS === 'true' && useLocize, // set to the useLocize constant (above) if you want to upload missing translations
  nsSeparator: false, // allow keys to be phrases having `:`, `.`
  keySeparator: false, // allow keys to be phrases having `:`, `.`
};

/** @type {import('next-i18next').UserConfig} */
module.exports = {
  i18n: {
    locales: ACCEPTED_LOCALES,
    defaultLocale: DEFAULT_LOCALE,
  },
  ...settings,
  localePath: path.resolve('./public/locales'),
};
